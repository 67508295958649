import React, { useEffect, useState, useMemo } from 'react';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { useParams, useHistory } from 'react-router-dom';
import {
  get, getOr, first, isEmpty, last,
} from 'lodash/fp';
import groupBy from 'lodash/groupBy';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import useHeaderComponentsMutation from '../../Hooks/useHeaderComponentsMutation';
import {
  AlignCenter,
  LinkButton,
  InnerFormTitle,
} from '../../Components/Auth/Layout';
import Search from '../../Components/Search/Index';
import useEventsUserPermissionChecker from '../../Hooks/useEventsUserPermissionChecker';
import useUserPermissionChecker from '../../Hooks/userUserPermissionChecker';
import {
  FETCH_COMPETITION_MEMBERS,
  SEARCH_COMPETITION_MEMBERS,
  FETCH_EVENTS_USERS_BY_USERS,
  FETCH_EVENT_USER_DETAIL,
  FETCH_EVENT_DETAIL,
  FETCH_EVENT_COMPETITION_DETAIL,
  CHECK_TEAM_FINALIZE,
} from '../../GraphQL/Queries';
import {
  REMOVE_MEMBER,
  JOIN_EVENT_COMPETITION,
  WAITLIST_SWAP,
} from '../../GraphQL/Mutations';
import useCurrentUser from '../../Hooks/userCurrentUser';
import Participant from '../../Components/Participant/Index';
import BackLink from '../../Components/BackLink';
import CROSS from '../../Images/icons/mist-cross.png';
import { ButtonLink } from '../../Components/Form';
import ADD_SMALL_ICON from '../../Images/icons/add-small.png';
import JoinEventCompetitionsFromRoster from './JoinEventCompetitionsFromRoster';
import GenericAlert from '../../Components/GenericAlert';
import useGraphQLErrorExtractor from '../../Hooks/useGraphQLErrorExtractor';
import useFilterArray from '../../Hooks/useFilterArray';
import useSearchThroughMultiArray from '../../Hooks/useSearchThroughMultiArray';
import useChangePriority from '../../Hooks/useChangePriority';
import Loader from '../../Components/Loader';
import Permissions from '../../Constants/PermissionConstrants';
import useUpdateCompetitionMembersCache from '../../Hooks/useUpdateCompetitionMembersCache';
import SearchDialog from '../../Components/SearchDialog/Index';
import SEARCH from '../../Images/search.png';
import { ACTIVE_STATUS } from '../../Constants/AppConstants';

const EditCompetitionRoster = () => {
  useHeaderComponentsMutation({ title: 'COMPETITION ROSTER' });
  const {
    currentUser,
  } = useCurrentUser(true);
  const { id } = useParams();
  const { schoolId } = useParams();
  const history = useHistory();
  const checkPermission = useUserPermissionChecker(currentUser);
  const [searchCompetitionMembers, setSearchCompetitionMembers] = useState([]);
  const [totalcompetitionMembers, setTotalcompetitionMembers] = useState([]);
  const [selectedCompetititionId, setSelectedCompetititionId] = useState('');
  const [selectedMemberIdForRemoval, setSelectedMemberIdForRemoval] = useState('');
  const [selectedCompetitionIdForRemoval, setSelectedCompetitionIdForRemoval] = useState('');
  const [selectedCompetition, setSelectedCompetition] = useState({});
  const [addCompetition, setAddCompetition] = useState(false);
  const [searchStringLength, setSearchStringLength] = useState(0);
  const [filteredEventCompetitions, setFilteredEventCompetitions] = useState([]);
  const [newlyAddedCompetitions, setNewlyAddedCompetitions] = useState([]);
  const [swappingEventCompetitions, setSwappingEventCompetitions] = useState([]);
  const extractError = useGraphQLErrorExtractor();
  const changePriority = useChangePriority();
  const filterArray = useFilterArray();
  const filterMultiArray = useSearchThroughMultiArray();

  const [fetchCompetitionMembers] = useLazyQuery(
    FETCH_COMPETITION_MEMBERS, {
      onCompleted: (data) => {
        setTotalcompetitionMembers(data.competitionRoster);
        setSearchCompetitionMembers(data.competitionRoster);
      },
    },
  );


  const updateCache = useUpdateCompetitionMembersCache(
    id, getOr(0, 'fetchSchools[0].id', currentUser),
  );

  const [fetchEventsUsersByUsers, { data: dataEventsUsers }] = useLazyQuery(
    FETCH_EVENTS_USERS_BY_USERS,
  );

  const eventsUsers = getOr([], 'fetchEventsUsersByUser', dataEventsUsers);

  const eventsUser = useMemo(() => first(filterArray(eventsUsers, 'fetchRole.title', 'Captain')), [eventsUsers, filterArray]);

  const checkPermissionofEventsUser = useEventsUserPermissionChecker(eventsUser);

  const [fetchEventDetail, { data: dataEvent }] = useLazyQuery(
    FETCH_EVENT_DETAIL,
  );

  const totalEventCompetitions = filterArray(
    getOr([], 'fetchEventDetail.fetchEventCompetitions', dataEvent),
    'status',
    'active',
  );

  const searchEvents = (e) => {
    setSearchStringLength(e.target.value.length);
    const selectedCompetitionIds = searchCompetitionMembers.map(
      (obj) => (obj.fetchEventCompetition ? obj.fetchEventCompetition.id : obj.id),
    );
    setFilteredEventCompetitions(
      totalEventCompetitions.filter(
        (eventCompetition) => eventCompetition.title.toLowerCase().includes(e.target.value),
      ).filter(
        (eventCompetition) => !selectedCompetitionIds.includes(eventCompetition.id),
      ),
    );
  };

  useEffect(() => {
    fetchCompetitionMembers({
      variables: {
        eventId: parseInt(id, 10),
        schoolId: parseInt(schoolId, 10),
      },
    });
  }, [currentUser, fetchCompetitionMembers, id, schoolId]);


  useEffect(() => {
    if (currentUser) {
      fetchEventsUsersByUsers({
        variables: {
          userId: parseInt(currentUser.id, 10),
          eventId: parseInt(id, 10),
          schoolId: parseInt(schoolId, 10),
        },
      });
    }
  }, [currentUser, fetchEventsUsersByUsers, id, schoolId]);

  useEffect(() => {
    if (currentUser) {
      fetchEventDetail({
        variables: {
          id: parseInt(id, 10),
        },
      });
    }
  }, [currentUser, fetchEventDetail, id]);

  const [checkTeamFinalize, { data: dataCheckTeamFinalize }] = useLazyQuery(
    CHECK_TEAM_FINALIZE,
  );

  const teamFinalize = getOr([], 'checkTeamFinalize', dataCheckTeamFinalize);

  useEffect(() => {
    if (currentUser && !isEmpty(schoolId)) {
      checkTeamFinalize({
        variables: {
          eventId: parseInt(id, 10),
          schoolId: parseInt(schoolId, 10),
        },
      });
    }
  }, [currentUser, checkTeamFinalize, schoolId, id]);

  const [competitionRosterSearch] = useLazyQuery(
    SEARCH_COMPETITION_MEMBERS,
    {
      onCompleted: (data) => {
        setSearchCompetitionMembers(data.searchCompetitionRoster);
      },
    },
  );

  const searchCompetitionRoster = (e) => {
    if (!isEmpty(schoolId)) {
      competitionRosterSearch({
        variables: {
          eventId: parseInt(id, 10),
          schoolId: parseInt(schoolId, 10),
          query: e.target.value,
        },
      });
    }
  };

  const [waitListSwap, { loading: loadingSwap }] = useMutation(
    WAITLIST_SWAP,
    {
      onCompleted: (data) => {
        updateCache(data.waitlistSwap.competitions);
        history.push(`/events/${id}/competition-roster/${schoolId}`);
      },
    },
  );

  const swapWaitlist = () => {
    const toBeWappedEventCompetitions = swappingEventCompetitions;
    if (!isEmpty(toBeWappedEventCompetitions)) {
      const eventCompetitionsArray = [];
      for (let i = 0; i < toBeWappedEventCompetitions.length; i += 1) {
        const obj = {};
        obj.eventCompetitionId = parseInt(
          toBeWappedEventCompetitions[i].fetchEventCompetition
            ? toBeWappedEventCompetitions[i].fetchEventCompetition.id
            : id, 10,
        );
        obj.members = [];
        for (let j = 0; j < toBeWappedEventCompetitions[i].fetchMembers.length; j += 1) {
          const innerObj = {};
          innerObj.memberId = parseInt(toBeWappedEventCompetitions[i].fetchMembers[j].id, 10);
          innerObj.competitionPriority = toBeWappedEventCompetitions[i]
            .fetchMembers[j].competitionPriority;
          obj.members.push(innerObj);
        }
        eventCompetitionsArray.push(obj);
      }
      waitListSwap({
        variables: {
          eventCompetitions: eventCompetitionsArray,
          eventId: parseInt(id, 10),
          schoolId: parseInt(schoolId, 10),
        },
      });
    } else {
      history.push(`/events/${id}/competition-roster`);
    }
  };


  const onDragEnd = (result) => {
    const intialIndex = result.source.index;
    if (result.destination) {
      const competitionRosterObj = filterMultiArray(totalcompetitionMembers, 'fetchMembers', result.draggableId, 'id');
      setSwappingEventCompetitions(swappingEventCompetitions.concat(
        competitionRosterObj,
      ));
      const searchedIndex = totalcompetitionMembers.findIndex(
        (item) => item.id === competitionRosterObj.id,
      );
      const finalIndex = result.destination.index;
      const changedCompetitions = totalcompetitionMembers;
      let competitionMembers = competitionRosterObj.fetchMembers;

      competitionMembers = changePriority(
        competitionMembers,
        competitionMembers[intialIndex],
        competitionMembers[finalIndex],
        'competitionPriority',
        finalIndex,
        intialIndex,
      );
      changedCompetitions[searchedIndex].fetchMembers = competitionMembers;
      setSearchCompetitionMembers(changedCompetitions);
      updateCache(changedCompetitions);
    }
  };

  const [deleteMember, { loading: removeLoading }] = useMutation(
    REMOVE_MEMBER,
    {
      onCompleted: () => {
        const tempIndex = totalcompetitionMembers.findIndex(
          (item) => item.id === selectedCompetitionIdForRemoval,
        );
        if (tempIndex !== -1) {
          const filteredTempCompetitions = totalcompetitionMembers;
          const filteredTempMembers = totalcompetitionMembers[tempIndex].fetchMembers.filter(
            (obj) => parseInt(obj.id, 10) !== parseInt(selectedMemberIdForRemoval, 10),
          );
          filteredTempCompetitions[tempIndex].fetchMembers = filteredTempMembers;
          setSearchCompetitionMembers(filteredTempCompetitions);
          updateCache(filteredTempCompetitions);
          if (filteredTempCompetitions.map(
            (a) => a.fetchMembers.length,
          ).every((item) => item === 0)
          ) {
            updateCache([]);
            history.goBack();
          }
        }
      },
    },
  );

  const removeMember = (memberId, competitionId) => {
    setSearchCompetitionMembers([]);
    setSelectedMemberIdForRemoval(memberId);
    setSelectedCompetitionIdForRemoval(competitionId);
    deleteMember({
      variables: {
        memberId: parseInt(memberId, 10),
      },
    });
  };

  const showForm = (competition) => {
    setSelectedCompetition(competition);
    setSelectedCompetititionId(
      competition.fetchEventCompetition
        ? competition.fetchEventCompetition.id
        : competition.id,
    );
  };

  const hideForm = () => {
    setSelectedCompetititionId('');
  };

  const [joinEventCompetition, { error, loading }] = useMutation(JOIN_EVENT_COMPETITION, {
    onCompleted: (data) => {
      const checkedId = data.joinEventCompetition.members[0].fetchEventCompetition.competitionId;
      let tempObjIndex = totalcompetitionMembers.findIndex(
        (item) => parseInt(item.id, 10) === checkedId,
      );
      if (tempObjIndex === -1) {
        tempObjIndex = totalcompetitionMembers.findIndex(
          (item) => parseInt(item.id, 10) === parseInt(
            data.joinEventCompetition.members[0].fetchEventCompetition.id, 10,
          ),
        );
      }
      const filteredObj = totalcompetitionMembers;
      if (!filteredObj[tempObjIndex].fetchMembers) {
        filteredObj[tempObjIndex].fetchMembers = [];
      }
      filteredObj[tempObjIndex].fetchMembers = filteredObj[tempObjIndex]
        .fetchMembers.concat(
          data.joinEventCompetition.members[0],
        );

      setSearchCompetitionMembers(filteredObj);
      updateCache(filteredObj);
      setSelectedCompetititionId('');
    },
  });


  const [fetchEventCompetitionDetail] = useLazyQuery(
    FETCH_EVENT_COMPETITION_DETAIL, {
      onCompleted: (data) => {
        const eventCompetitionObj = data.fetchEventCompetitionDetail;
        const updatedCompetitions = searchCompetitionMembers.concat(
          eventCompetitionObj,
        );
        setSearchCompetitionMembers(updatedCompetitions);
        setTotalcompetitionMembers(updatedCompetitions);
        setNewlyAddedCompetitions(newlyAddedCompetitions.concat(
          eventCompetitionObj.id,
        ));
      },
    },
  );

  const getSelectedCompetition = (e) => {
    if (!isEmpty(e.target.id)) {
      fetchEventCompetitionDetail({
        variables: {
          id: parseInt(e.target.id, 10),
        },
      });
      setAddCompetition(false);
      setSearchStringLength(0);
    }
  };


  const [fetchEventUserDetail] = useLazyQuery(
    FETCH_EVENT_USER_DETAIL,
    {
      onCompleted: (data) => {
        if (isEmpty(selectedCompetition.fetchMembers)) {
          joinEventCompetition({
            variables: {
              eventsUserId: parseInt(data.fetchEventsUserDetail.id, 10),
              userId: parseInt(data.fetchEventsUserDetail.fetchUser.id, 10),
              status: ACTIVE_STATUS,
              eventCompetitionId: parseInt(selectedCompetititionId, 10),
            },
          });
        } else {
          joinEventCompetition({
            variables: {
              eventsUserId: parseInt(data.fetchEventsUserDetail.id, 10),
              userId: parseInt(data.fetchEventsUserDetail.fetchUser.id, 10),
              status: ACTIVE_STATUS,
              eventCompetitionId: parseInt(
                selectedCompetition.fetchMembers[0].eventCompetitionId, 10,
              ),
            },
          });
        }
      },
    },
  );

  const competitionsByCategory = groupBy(
    searchCompetitionMembers, (counter) => (counter.fetchCompetition
      ? counter.fetchCompetition.fetchCategory.id
      : counter.fetchCategory.id),
  );


  const getSelectedEvent = (eventUserId) => {
    fetchEventUserDetail({
      variables: {
        id: parseInt(eventUserId, 10),
      },
    });
  };

  if (loading || removeLoading || loadingSwap) {
    return <Loader />;
  }

  return (
    <>
      <div className="mt-4" />
      {error ? <GenericAlert>{extractError(error)}</GenericAlert> : null}
      {totalcompetitionMembers.length && !teamFinalize
        ? (
          <Search
            placeholder="Search by Name or Competition"
            onChange={(e) => searchCompetitionRoster(e)}
          />
        )
        : null}
      {totalcompetitionMembers.length === 0
        ? (
          <div className="alert alert-warning mt-3" role="alert">
              No body join your team yet!. Invite your school mates to join your team
          </div>
        )
        : null}
      <div className="container  pl-0 pr-0">
        <div className="row">
          <div className="col-md-12">
            <div className="row">
              {searchCompetitionMembers.length === 0
                ? null
                : (
                  <div className="col-md-6 col-6 font-weight-bold">
                  Competitions
                  </div>
                )}
            </div>
            <div className="pt-3" />
            {Object.keys(competitionsByCategory).map((categoryId, index) => (
              <InnerFormTitle key={categoryId}>
                {get('fetchEventCompetition', competitionsByCategory[categoryId][0])
                  ? (
                    <p>
                      {get('title', get('fetchCategory', competitionsByCategory[categoryId][0]))}
                    </p>
                  )

                  : (
                    <p>
                      {get('title', get('fetchCompetition.fetchCategory', competitionsByCategory[categoryId][0]))}
                    </p>
                  )}
                {Object.values(competitionsByCategory)[index].map((competition) => (
                  <>
                    {get('fetchEventCompetition', competition)
                      ? (
                        <div className="row mt-4 mb-2">
                          <div className="col-md-12 col-12 h5  font-weight-bold">
                            {get('fetchEventCompetition.title', competition)}
                          </div>
                        </div>
                      )
                      : (
                        <div className="row mt-4 mb-2">
                          <div className="col-md-12 col-12 h5  font-weight-bold">
                            {get('title', competition)}
                          </div>
                        </div>
                      )}
                    {!isEmpty(competition.fetchMembers)
                      ? (
                        <DragDropContext onDragEnd={onDragEnd}>
                          <Droppable droppableId="droppable">
                            {(provided) => (
                              <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                              >
                                {competition.fetchMembers.map((member, Index) => (
                                  <Draggable
                                    key={member.id}
                                    draggableId={member.id}
                                    index={Index}

                                  >
                                    {(providedInner) => (
                                      <div
                                        ref={providedInner.innerRef}
                                        {...providedInner.draggableProps}
                                      >
                                        <div className="row pl-3">
                                          <Participant
                                            user={member}
                                            index={Index}
                                            color={member.status === 'joined'
                                              ? '#389446'
                                              : '#3E6796'}
                                            imageAttatched={member.status === 'joined'
                                              ? null
                                              : 'true'}
                                            removeIcon={CROSS}
                                            onRemove={() => removeMember(
                                              member.id, competition.id,
                                            )}
                                            dragHandle={providedInner.dragHandleProps}
                                            reason={member.reason}
                                          />
                                        </div>
                                      </div>
                                    )}
                                  </Draggable>
                                ))}
                                {provided.placeholder}
                              </div>
                            )}
                          </Droppable>
                        </DragDropContext>
                      )
                      : null}
                    {selectedCompetititionId === (
                      get('fetchEventCompetition', competition)
                        ? competition.fetchEventCompetition.id
                        : competition.id
                    )
                      ? (
                        <JoinEventCompetitionsFromRoster
                          competitionTitle={get('title', competition)}
                          onClick={() => hideForm()}
                          eventId={id}
                          schoolId={schoolId || last(currentUser.fetchSchools).id}
                          onSelect={getSelectedEvent}
                          eventCompetitionId={
                            !isEmpty(selectedCompetition.fetchMembers)
                              ? selectedCompetition.fetchMembers[0].eventCompetitionId
                              : selectedCompetititionId
                          }
                        />
                      )
                      : (
                        <>
                          <ButtonLink
                            backgroundcolor="#F4AB37"
                            onClick={() => showForm(competition)}
                            fontColor="#FFF"
                            border="none"
                            addNewText="Add Student"
                            imageAttached={ADD_SMALL_ICON}
                            checkPermission={
                            ((checkPermissionofEventsUser(Permissions.CAN_MANAGE_TEAM_MEMBERS))
                            || checkPermission(Permissions.CAN_MANAGE_REGION))
                            && !teamFinalize
                          }
                          />
                        </>
                      )}
                  </>
                ))}

              </InnerFormTitle>
            ))}
            {addCompetition === false
              ? (
                <ButtonLink
                  backgroundcolor="#F4AB37"
                  onClick={() => setAddCompetition(true)}
                  fontColor="#FFF"
                  border="none"
                  addNewText="Add Competition"
                  imageAttached={ADD_SMALL_ICON}
                  checkPermission={
                    ((checkPermissionofEventsUser(Permissions.CAN_MANAGE_TEAM_MEMBERS))
                    || checkPermission(Permissions.CAN_MANAGE_REGION))
                    && !teamFinalize
                  }
                />
              )
              : (
                <SearchDialog
                  placeholder="Search"
                  onChange={searchEvents}
                  data={filteredEventCompetitions}
                  stringlength={searchStringLength}
                  onClick={getSelectedCompetition}
                  displayattribute="title"
                  imageattatched={SEARCH}
                  groupedsearch
                />
              )}
            <LinkButton
              onClick={swapWaitlist}
              backgroundcolor="#F4AB37"
              fontcolor="#FFF"
              border="none"
              loading={loadingSwap}
            >
              SAVE
            </LinkButton>
          </div>
        </div>
      </div>
      <AlignCenter>
        <BackLink to={`/event-manage/${id}`}>
       Manage Event
        </BackLink>
      </AlignCenter>
    </>
  );
};

export default EditCompetitionRoster;
