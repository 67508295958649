import { gql } from '@apollo/client';
import {
  REGION,
  ROLE,
  USER,
  ROLE_PERMISSION,
  SCHOOL,
  COUPON,
  CATEGORY,
  COMPETITION,
  REGISTRATION_OPTION,
  EVENT_COMPETITION,
  REGIONSUSER,
  PERMISSION,
  STUDENT_SCHOOL,
  COALITION,
  MEMBER,
  ORDER,
  LINE_ITEM,
  EVENT_USER,
  COMMENT,
  TENANT,
  INVITATION,
  COUNTRY,
  STATE,
  SLIM_EVENT_USER,
} from './Types';

const QUERY_USER = `
  ${USER}
  emergencyContactName
  emergencyContactNumber
  shirtSize
  race
`;

export const FETCH_TENANT_DETAIL = gql`
  query fetchTenantDetail($id: Int!){
    fetchTenantDetail(
      id: $id
    ){
      ${TENANT}
    }
  }
`;

export const FETCH_ROLES = gql`
  query fetchRoles($tenantId: Int!){
    fetchRoles(
      tenantId: $tenantId
    ){
      ${ROLE}
    }
  }
`;

export const FETCH_ROLE_DETAIL = gql`
  query fetchRoleDetail($id: Int!) {
    fetchRoleDetail(id: $id) {
      ${ROLE}
      permissions {
        id
        title
        displayName
      }
    }
  }
`;

export const FETCH_ROLE_PERMISSIONS = gql`
  query fetchRolePermissions($roleId: Int!) {
    fetchRolePermissions(
      roleId: $roleId
    ){
      ${ROLE_PERMISSION}
      fetchPermissions{
        ${PERMISSION}
      }
    }
  }
`;

export const FETCH_REGION = gql`
  query fetchRegions($userId: Int!){
    fetchRegions(userId: $userId) {
      ${REGION}
    }
  }
`;

export const FETCH_REGION_BY_TENANT = gql`
  query fetchRegionsByTenant($tenantId: Int!){
    fetchRegionsByTenant(tenantId: $tenantId) {
      id
      name
    }
  }
`;

export const FETCH_REGION_DETAIL = gql`
  query fetchRegionDetail($id: Int!){
    fetchRegionDetail(id: $id) {
      ${REGION}
    }
  }
`;


export const FETCH_SCHOOLS = gql`
  query fetchSchools($tenantId: Int!){
    fetchSchools(tenantId: $tenantId){
      ${SCHOOL}
    }
  }
`;

export const FETCH_MY_SCHOOLS = gql`
  query mySchools($userId: Int!){
    mySchools(userId: $userId){
      ${SCHOOL}
    }
  }
`;

export const FETCH_REASSIGN_SCHOOLS = gql`
  query fetchReassignSchools($schoolId: Int!, $tenantId: Int!){
    fetchReassignSchools(schoolId: $schoolId, tenantId: $tenantId){
      ${SCHOOL}
    }
  }
`;

export const FETCH_SCHOOL_USERS = gql`
  query fetchSchoolUsers($schoolId: Int!){
    fetchSchoolUsers(schoolId: $schoolId){
      ${STUDENT_SCHOOL}
    }
  }
`;


export const SEARCH_SCHOOLS = gql`
  query searchSchools($userId: Int!, $query: String!){
    searchSchools(userId: $userId, query: $query){
      ${SCHOOL}
    }
  }
`;

export const FETCH_SCHOOL_DETAIL = gql`
  query fetchSchoolDetail($id: Int!){
    fetchSchoolDetail(id: $id){
      ${SCHOOL}
    }
  }
`;

export const FETCH_USERS = gql`
  query fetchUsers($tenantId: Int!){
    fetchUsers(tenantId: $tenantId){
      ${QUERY_USER}
    }
  }
`;

export const FETCH_USER_DETAIL = gql`
  query fetchUserDetail($id: Int!){
    fetchUserDetail(id: $id){
      ${QUERY_USER}
    }
  }
`;

export const SEARCH_USERS = gql`
  query searchUsers($query: String!){
    searchUsers(query: $query){
      ${QUERY_USER}
    }
  }
`;

export const FETCH_COMPETITIONS = gql`
  query fetchCompetitions($tenantId: Int!, $categoryId: Int!){
    fetchCompetitions(tenantId: $tenantId, categoryId: $categoryId){
      id
      status
      title
      tenantId
      categoryId
      matchup
      maxTeam
      minTeam
      maxSubmission
      onlineSubmission
      requiresAdditionalCompetition
      group
      genderEnable
      description
      fetchCategory{
        id
        title
        tenantId
      }
      fetchBallot {
        id
      }
    }
  }
`;

export const FETCH_COMPETITION_DETAIL = gql`
  query fetchCompetitionDetail($id: Int!){
    fetchCompetitionDetail(id: $id){
      id
      status
      title
      tenantId
      categoryId
      matchup
      maxTeam
      minTeam
      maxSubmission
      onlineSubmission
      requiresAdditionalCompetition
      group
      genderEnable
      description
      fetchCategory{
        id
        title
        tenantId
      }
      fetchBallot {
        id
      }
    }
  }
`;

export const FETCH_COMPETITION_LIST = gql`
  query listCompetitions($tenantId: Int!){
    listCompetitions(tenantId: $tenantId){
      ${COMPETITION}
    }
  }
`;

export const SEARCH_COMPETITIONS = gql`
  query searchCompetitions($query: String!){
    searchCompetitions(query: $query){
      ${COMPETITION}
    }
  }
`;

export const FETCH_CATEGORIES = gql`
  query fetchCategories($tenantId: Int!){
    fetchCategories(tenantId: $tenantId){
      ${CATEGORY}
    }
  }
`;

export const FETCH_CATEGORY_DETAIL = gql`
  query fetchCategoryDetail($id: Int!){
    fetchCategoryDetail(id: $id){
      ${CATEGORY}
    }
  }
`;

export const CURRENT_USER = gql`
  query currentUser {
    currentUser {
      ${QUERY_USER}
      fetchRole {
        ${ROLE}
        permissions {
          id
          title
          displayName
        }
      }
      fetchRegionsUsers {
        ${REGIONSUSER}
        fetchRole {
          ${ROLE}
          permissions {
            id
            title
            displayName
          }
        }
      }
    }
  }
`;

export const REGISTRATION_OPTION_QUERY = gql`
  {
    components @client {
      key
      value
    }
    id @client
    roleId @client
    tenantId @client
  }
`;

export const HEADER_COMPONENTS = gql`
  {
    components @client {
      key
      value
    }
    title @client
    backLink @client
  }
`;

export const FETCH_COUPONS = gql`
  query fetchCoupons($tenantId: Int!){
    fetchCoupons(tenantId: $tenantId) {
      ${COUPON}
    }
  }
`;

export const SEARCH_COUPONS = gql`
  query searchCoupons($query: String!){
    searchCoupons(query: $query){
      ${COUPON}
    }
  }
`;

export const FETCH_COUPON_DETAIL = gql`
  query fetchCouponDetail($id: Int!){
    fetchCouponDetail(id: $id) {
      ${COUPON}
    }
  }
`;

export const BROWSE_EVENTS = gql`
  query fetchActiveEvents{
    fetchActiveEvents {
      id
      title
      startDate
      endDate
      submissionDeadline
      location
      regionId
      coverImageUrl
      fee
      registrationEarlyDeadline
      fetchRegistrationOptions {
        id
        title
        fetchRole {
          id
          title
        }
        finalDeadline
      }
    }
  }
`;

export const FETCH_MY_EVENTS = gql`
  query myEvents {
    myEvents {
      title
      id
      status
      coverImageUrl
    }
  }
`;

export const SEARCH_EVENTS = gql`
  query searchEvents($query: String!) {
    searchEvents(query: $query) {
      id
      status
      coverImageUrl
      title
    }
  }
`;

export const FETCH_EVENT_DETAIL = gql`
  query fetchEventDetail($id: Int!) {
    fetchEventDetail(id: $id) {
      id
      title
      label
      tenantId
      status
      regionId
      startDate
      endDate
      submissionDeadline
      location
      allowCoalition
      restrictToRegion
      volunteerApplicationLink
      judgeApplicationLink
      sponsorApplicationLink
      enableMinMax
      qualifiedAttendingPreviousEvent
      competitionPerCategory
      competeAdditionalCompetition
      allowsMultipleSports
      termsAndConditions
      coverImageUrl
      fee
      registrationEarlyDeadline
      registrationFinalDeadline
      approveSchoolsCount
      unapproveSchoolsCount
      currency
      merchandise {
        id
        title
        cost
      }
      fetchEventCompetitions{
        id
        eventId
        tenantId
        competitionId
        status
        gender
        level
        title
        fetchCompetition{
          id
          title
          tenantId
          categoryId
          fetchCategory{
            id
            title
            status
            tenantId
          }
        }
      }
      fetchRegistrationOptions{
        id
        title
        tenantId
        roleId
        eventId
        fee
        lateFee
        capacity
        earlyDeadline
        finalDeadline
        status
        minAge
        maxAge
      }
      allEventsUsers{
        id
        tenantId
        status
        schoolId
        userId
        parentGuardianEmail
        parentGuardianSignature
        roleId
        signIn
        teamFinalize
        fetchRole{
          id
          title
        }
        fetchUser{
          id
          email
          status
          phone
          userType
          gender
          tenantId
          dateOfBirth
          firstname
          lastname
        }
      }
    }
  }
`;

export const FETCH_EVENT_HEADER = gql`
  query fetchEventDetail($id: Int!) {
    fetchEventDetail(id: $id) {
      id
      title
      label
      tenantId
      status
      regionId
      startDate
      endDate
      submissionDeadline
      location
      allowCoalition
      restrictToRegion
      volunteerApplicationLink
      judgeApplicationLink
      sponsorApplicationLink
      enableMinMax
      qualifiedAttendingPreviousEvent
      competitionPerCategory
      competeAdditionalCompetition
      termsAndConditions
      coverImageUrl
      fee
      registrationEarlyDeadline
      registrationFinalDeadline
      approveSchoolsCount
      unapproveSchoolsCount
      discordLink
      financialAidFormLink
      currency
      merchandise {
        id
        title
        cost
      }
      fetchRegistrationOptions {
        id
        title
      }
    }
  }
`;

export const FETCH_EVENT_APP_INFO = gql`
  query fetchEventAppDetail($id: Int!) {
    fetchEventAppDetail(id: $id) {
      id
      title
      locations {
        id
        name
        longitude
        latitude
      }
      schedule {
        scheduleEvents {
          id
          title
          type
          description
          startTime
          endTime
          locationId
          fetchLocation {
            id
            name
          }
          room
          associatedEventCompetitionId
          fetchAssociatedEventCompetition {
            id
            title
          }
        }
      }
      announcements {
        id
        title
        body
        link
        targetType
        targetId
        isImportant
        createdAt
        fetchSchools {
          id
          name
        }
        fetchCompetitions {
          id
          title
        }
        fetchRoles {
          id
          title
        }
      }
      resources {
        id
        title
        type
        body
        attachmentName
        attachmentUrl
      }
      fetchEventCompetitions {
        id
        title
      }
    }
  }
`;

export const BROWSE_EVENT_DETAILS = gql`
  query fetchEventDetail($id: Int!){
    fetchEventDetail(id: $id) {
      fetchEventCompetitions {
        status
        title
        id
        eventId
      }
    }
  }
`;

export const FETCH_EXPIRED_EVENTS = gql`
  query ($userId: Int!){
    fetchExpiredEvents(userId: $userId) {
      id
      title
    }
  }
`;

export const FETCH_REGISTRATION_OPTIONS = gql`
  query fetchRegistrationOptions($tenantId: Int!, $eventId: Int!){
    fetchRegistrationOptions(tenantId: $tenantId, eventId: $eventId) {
      ${REGISTRATION_OPTION}
    }
  }
`;

export const FETCH_REGISTRATION_OPTIONS_BY_DEADLINE = gql`
  query registrationOptionByDeadline($tenantId: Int!, $eventId: Int!){
    registrationOptionByDeadline(tenantId: $tenantId, eventId: $eventId) {
      ${REGISTRATION_OPTION}
    }
  }
`;

export const FETCH_REGISTRATION_OPTION_DETAIL = gql`
  query fetchRegistrationOptionDetail($id: Int!){
    fetchRegistrationOptionDetail(id: $id) {
      ${REGISTRATION_OPTION}
    }
  }
`;

export const FETCH_EVENT_COMPETITIONS = gql`
  query fetchEventCompetitions($tenantId: Int!){
    fetchEventCompetitions(tenantId: $tenantId) {
      ${EVENT_COMPETITION}
    }
  }
`;

export const FETCH_EVENT_COMPETITION_DETAIL = gql`
  query fetchEventCompetitionDetail($id: Int!){
    fetchEventCompetitionDetail(id: $id) {
      ${EVENT_COMPETITION}
    }
  }
`;

export const FETCH_EVENT_COMPETITION_DETAIL_JUDGING = gql`
  query fetchEventCompetitionDetail($id: Int!){
    fetchEventCompetitionDetail(id: $id) {
      id
      title
      fetchCompetition {
        id
        fetchBallot {
          id
          fetchBallotSections {
            id
            title
            description
            totalPoints
          }
        }
      }
    }
  }
`;

export const FETCH_EVENT_COMPETITION_ATTACHMENTS = gql`
  query fetchEventCompetitionDetail($id: Int!){
    fetchEventCompetitionDetail(id: $id) {
      id
      title
      allJudgables {
        id
        type
        code
        attachments {
          id
          attachmentName
          attachmentUrl
          createdAt
          updatedAt
        }
        members {
          id
          type
          code
          attachments {
            id
            attachmentName
            attachmentUrl
            createdAt
            updatedAt
          }
        }
      }
    }
  }
`;

export const SEARCH_ADMIN_USERS = gql`
  query searchAdminUsers($query: String!){
    searchAdminUsers(query: $query){
      ${QUERY_USER}
    }
  }
`;

export const FETCH_COALITIONS = gql`
  query fetchCoalitions($eventId: Int!){
    fetchCoalitions(eventId: $eventId, ) {
      ${COALITION}
    }
  }
`;

export const FETCH_COALITION_DETAIL = gql`
  query fetchCoalitionDetail($id: Int!){
    fetchCoalitionDetail(id: $id ) {
      ${COALITION}
    }
  }
`;

export const GET_ADMIN_USERS_FOR_REGION = gql`
  {
    adminUsers @client
  }
`;

export const FETCH_TEAM_MEMBERS = gql`
  query viewMyTeam($eventId: Int!,$schoolId: Int!){
    viewMyTeam(eventId: $eventId,schoolId: $schoolId){
      id
      roleId
      userId
      schoolId
      eventId
      status
      signIn
      createdAt
      teamFinalize
      fetchUser{
        id
        email
        status
        phone
        userType
        gender
        tenantId
        dateOfBirth
        firstname
        lastname
        profileImageUrl
        code
      }
      fetchRole{
        id
        title
        tenantId
        status
      }
      fetchMembers{
        id
        userId
        eventsUserId
        eventCompetitionId
        status
        competitionPriority
        memberPriority
        reason
        fetchEventCompetition{
          id
          eventId
          tenantId
          status
          competitionId
          level
          title
        }
      }
  }
}`;

export const SEARCH_TEAM_MEMBERS = gql`
query searchUsersByCompetition($eventId: Int!,$schoolId: Int!,$query: String!){
  searchUsersByCompetition(eventId: $eventId,schoolId: $schoolId,query: $query){
    ${MEMBER}
  }
}
`;
export const FETCH_COACH = gql`
  query fetchCoach($eventId: Int!,$schoolId: Int!){
    fetchCoach(eventId: $eventId,schoolId: $schoolId){
      ${EVENT_USER}
    }
  }
`;
export const FETCH_EVENT_USERS_BY_EVENT = gql`
  query fetchEventUsersByEvent($eventId: Int!, $schoolId: Int!){
    fetchEventUsersByEvent(eventId: $eventId, schoolId: $schoolId) {
      ${EVENT_USER}
    }
  }
`;

export const FETCH_EVENT_USER_DETAIL = gql`
  query fetchEventsUserDetail($id: Int!){
    fetchEventsUserDetail(id: $id) {
      ${EVENT_USER}
    }
  }
`;

export const FETCH_EVENTS_USERS_BY_USERS = gql`
  query fetchEventsUsersByUser($eventId: Int!, $userId: Int!, $schoolId: Int){
    fetchEventsUsersByUser(eventId: $eventId, userId: $userId, schoolId: $schoolId) {
      ${EVENT_USER}
      competitionsToJudge {
        id
        title
        rounds {
          id
          name
          status
        }
      }
    }
  }
`;

export const FETCH_EVENT_USERS_BY_SCHOOL = gql`
  query fetchEventUsersBySchool($eventId: Int!, $schoolId: Int!){
    fetchEventUsersBySchool(eventId: $eventId, schoolId: $schoolId) {
      ${EVENT_USER}
    }
  }
`;

export const SEARCH_EVENTS_USER = gql`
  query searchEventsUsers($eventId: Int!, $query: String!){
    searchEventsUsers(eventId: $eventId, query: $query){
      ${EVENT_USER}
    }
  }
`;

export const FETCH_ORDER_DETAIL = gql`
  query fetchOrderDetail($id: Int!){
    fetchOrderDetail(id: $id ) {
      ${ORDER}
    }
  }
`;

export const FETCH_UNAPPROVE_SCHOOLS_BY_EVENT = gql`
  query fetchUnapproveSchool($eventId: Int!){
    fetchUnapproveSchool(eventId: $eventId) {
      ${SCHOOL}
    }
  }
`;

export const FETCH_LINE_ITEM_DETAIL = gql`
  query fetchLineItemDetail($id: Int!){
    fetchLineItemDetail(id: $id ) {
      ${LINE_ITEM}
    }
  }
`;

export const FETCH_JOIN_EVENT_ROLES = gql`
  query fetchJoinEventRole{
    fetchJoinEventRole{
      ${ROLE}
    }
  }
`;

export const FETCH_COMPETITION_MEMBERS = gql`
query competitionRoster($eventId: Int!,$schoolId: Int!){
  competitionRoster(eventId:$eventId,schoolId:$schoolId){
    id
    status
    title
    tenantId
    fetchCategory{
      id
      title
      tenantId
    }
    fetchEventCompetition(eventId: $eventId){
      id
      title
      tenantId
    }
    fetchMembers(eventId:$eventId,schoolId: $schoolId){
      id
      userId
      eventsUserId
      eventCompetitionId
      status
      reason
      competitionPriority
      memberPriority
      fetchUser{
        id
        firstname
        lastname
      }
    }
  }
}
`;

export const SEARCH_COMPETITION_MEMBERS = gql`
query searchCompetitionRoster($eventId: Int!,$schoolId: Int!, $query: String!){
  searchCompetitionRoster(eventId: $eventId,schoolId: $schoolId,query: $query){
    id
    status
    title
    tenantId
    fetchCategory{
      id
      title
      tenantId
    }
    fetchEventCompetition(eventId: $eventId){
      id
      title
      tenantId
    }
    fetchMembers(eventId:$eventId,schoolId: $schoolId){
      id
      userId
      eventsUserId
      eventCompetitionId
      status
      reason
      competitionPriority
      memberPriority
      fetchUser{
        id
        firstname
        lastname
      }
    }
  }
}`;

export const CHECK_USER_JOIN_EVENT = gql`
  query checkUserJoinEvent($eventId: Int!, $userId: Int!){
    checkUserJoinEvent(eventId: $eventId, userId: $userId){
      ${EVENT_USER}
    }
  }
`;

export const FETCH_EVENT_USER_COMMENTS = gql`
  query fetchEventUserComments($eventsUsersId: Int!){
    fetchEventUserComments(eventsUsersId: $eventsUsersId) {
      ${COMMENT}
    }
  }
`;
export const MANAGE_MY_COMPETITIONS = gql`
  query manageMyCompetitions($eventId: Int!, $userId: Int!){
    manageMyCompetitions(eventId: $eventId, userId: $userId){
      ${MEMBER}
      fetchEventCompetitionAttachment {
        id
        attachmentName
        attachmentUrl
      }
    }
  }
`;

export const FETCH_ORDERS_BY_EVENT = gql`
  query fetchOrdersByEvent($eventId: Int!, $userId: Int!){
    fetchOrdersByEvent(eventId: $eventId, userId: $userId){
      ${ORDER}
    }
  }
`;

export const FETCH_ORDERS = gql`
  query fetchOrders($userId: Int!){
    fetchOrders(userId: $userId){
      ${ORDER}
    }
  }
`;

export const FETCH_EVENT_SCHOOL_STUDENTS = gql`
  query fetchEventSchoolStudents($eventId: Int!, $schoolId: Int!){
    fetchEventSchoolStudents(eventId: $eventId, schoolId: $schoolId){
      ${EVENT_USER}
    }
  }
`;

export const FETCH_EVENT_TEAMS = gql`
query eventTeams($eventId: ID!){
  eventTeams(eventId: $eventId){
    ${SCHOOL}
  }
}
`;

export const SEARCH_USER_IN_EVENTS_USER = gql`
  query searchUserInEventsUser($eventId: Int!, $schoolId: Int!, $query: String!, $eventCompetitionId: Int!){
    searchUserInEventsUser(eventId: $eventId, schoolId: $schoolId, query: $query, eventCompetitionId: $eventCompetitionId){
      ${EVENT_USER}
    }
  }

`;

export const FETCH_EVENT_GUESTS = gql`
  query eventGuests($eventId: ID!){
    eventGuests(eventId: $eventId){
      ${EVENT_USER}
    }
  }
`;

export const FETCH_EVENT_COACHES = gql`
query eventCoaches($eventId:ID!){
  eventCoaches(eventId:$eventId){
      id
      fetchUser {
        id
        firstname
        lastname
        code
        profileImageUrl
        fetchSchools {
          name
        }
      }
    }
  }
`;

export const FETCH_EVENT_ADMINS = gql`
query eventAdmins($eventId: ID!){
  eventAdmins(eventId:$eventId){
    ${EVENT_USER}
  }
}
`;

export const SEARCH_UNJOIN_USERS = gql`
  query searchUnregisteredUsers($eventId: Int!, $query: String!){
    searchUnregisteredUsers(eventId: $eventId, query: $query){
      ${QUERY_USER}
    }
  }
`;

export const FETCH_UNJOIN_USERS = gql`
  query fetchUnregisteredUsers($userId: Int!, $eventId: Int!){
    fetchUnregisteredUsers(userId: $userId, eventId: $eventId){
      ${QUERY_USER}
    }
  }
`;

export const FETCH_STUDENTS = gql`
  query eventStudents($eventId: ID!){
    eventStudents(eventId: $eventId){
      id
      fetchUser {
        id
        firstname
        lastname
        code
        profileImageUrl
        fetchSchools {
          name
        }
      }
    }
  }
`;

export const FETCH_REG_SPREADSHEET_COACHES = gql`
  query eventCoaches($eventId: ID!){
    eventCoaches(eventId: $eventId){
      status
      fetchUser {
        code
        firstname
        lastname
        gender
        email
        phone
        dateOfBirth
        defaultRegion {
          id
          name
        }
        emergencyContactName
        emergencyContactNumber
        shirtSize
      }
      fetchOrder {
        amount
        status
      }
      fetchRegistrationOption {
        title
      }
      fetchSchool {
        name
      }
      createdAt
    }
  }
`;

export const FETCH_REG_SPREADSHEET_GUESTS = gql`
  query eventGuests($eventId: ID!){
    eventGuests(eventId: $eventId){
      status
      fetchUser {
        code
        firstname
        lastname
        gender
        email
        phone
        dateOfBirth
        defaultRegion {
          id
          name
        }
        emergencyContactName
        emergencyContactNumber
        shirtSize
      }
      fetchOrder {
        amount
        status
      }
      fetchRegistrationOption {
        title
      }
      fetchSchool {
        name
      }
      createdAt
    }
  }
`;

export const FETCH_REG_SPREADSHEET_STUDENTS = gql`
  query eventStudents($eventId: ID!){
    eventStudents(eventId: $eventId){
      status
      fetchUser {
        code
        firstname
        lastname
        gender
        email
        phone
        dateOfBirth
        defaultRegion {
          id
          name
        }
        emergencyContactName
        emergencyContactNumber
        shirtSize
      }
      fetchOrder {
        amount
        status
      }
      fetchRegistrationOption {
        title
      }
      fetchSchool {
        name
      }
      createdAt
    }
  }
`;

export const FETCH_COMPS_SPREADSHEET_STUDENTS = gql`
  query eventStudents($eventId: ID!){
    eventStudents(eventId: $eventId){
      fetchUser {
        code
        firstname
        lastname
        email
        defaultRegion {
          id
          name
        }
      }
      fetchSchool {
        name
      }
      fetchRegistrationOption {
        id
        title
      }
    }
  }
`;

export const FETCH_REG_SPREADSHEET_CAPTAINS = gql`
  query eventCaptains($eventId: ID!){
    eventCaptains(eventId: $eventId){
      fetchUser {
        code
      }
    }
  }
`;

export const FETCH_REG_SPREADSHEET_TEAMS = gql`
  query eventSchoolTeams($eventId: ID!){
    eventSchoolTeams(eventId: $eventId){
      code
      school {
        name
      }
    }
  }
`;

export const FETCH_INVITATION_DETAIL_BY_CODE = gql`
  query fetchInvitationDetail($invitationCode: String!){
    fetchInvitationDetail(invitationCode: $invitationCode){
      ${INVITATION}
    }
  }
`;

export const FETCH_ACTIVE_COUNTIRES = gql`
  query fetchActiveCountries{
    fetchActiveCountries{
      ${COUNTRY}
    }
  }
`;

export const FETCH_STATES_BY_COUNTRY = gql`
  query fetchStatesByCountry($countryId: Int!){
    fetchStatesByCountry(countryId: $countryId){
      ${STATE}
    }
  }
`;


export const FETCH_EVENT_USERS_IN_EVENT = gql`
  query fetchEventUsersInEvent($eventId: Int!){
    fetchEventUsersInEvent(eventId: $eventId) {
      ${EVENT_USER}
    }
  }
`;


export const FETCH_EVENTS_USERS_BY_USER = gql`
  query eventsUsersByUser($userId: Int!){
    eventsUsersByUser(userId: $userId) {
      ${SLIM_EVENT_USER}
    }
  }
`;

export const FETCH_EVENTS_FROM_USER = gql`
  query fetchEventsFromUser($userId: Int!){
    fetchEventsFromUser(userId: $userId) {
      id
      title
    }
  }
`;

export const UNPAID_COUNT = gql`
  query fetchUnpaidUserCount($eventId: Int!, $schoolId: Int!){
    fetchUnpaidUserCount(eventId: $eventId, schoolId: $schoolId)
  }
`;

export const PENDING_REQUEST_COUNT = gql`
  query fetchPendingRequest($eventId: Int!, $schoolId: Int!){
    fetchPendingRequest(eventId: $eventId, schoolId: $schoolId)
  }
`;

export const CHECK_TEAM_FINALIZE = gql`
  query checkTeamFinalize($eventId: Int!, $schoolId: Int!){
    checkTeamFinalize(eventId: $eventId, schoolId: $schoolId)
  }
`;

export const USER_INVITATION_COUNT = gql`
  query userInvitationCount($invitableId: Int!, $invitableType: String!, $userId: Int!){
    userInvitationCount(invitableId: $invitableId, invitableType: $invitableType, userId: $userId)
  }
`;

export const INVITED_USERS = gql`
  query userInvitationList($invitableId: Int!, $invitableType: String!, $userId: Int!){
    userInvitationList(invitableId: $invitableId, invitableType: $invitableType, userId: $userId) {
      ${QUERY_USER}
    }
  }
`;

export const UNPAID_USERS = gql`
  query unpaidJoinCompetitions($eventId: Int!, $schoolId: Int!){
    unpaidJoinCompetitions(eventId: $eventId, schoolId: $schoolId) {
      id
      roleId
      userId
      schoolId
      eventId
      status
      teamFinalize
      fetchUser{
        id
        status
        userType
        gender
        tenantId
        firstname
        lastname
        profileImageUrl
        fetchSchools{
          id
          tenantId
          name
        }
      }
    }
  }
`;

export const TEAM_CAPTAIN = gql`
  query fetchTeamCaptian($eventId: Int!, $schoolId: Int!){
    fetchTeamCaptian(eventId: $eventId, schoolId: $schoolId) {
      ${EVENT_USER}
    }
  }
`;

export const FETCH_EVENT_SCHOOL_COACHES = gql`
  query fetchEventSchoolCoaches($eventId: Int!, $schoolId: Int!){
    fetchEventSchoolCoaches(eventId: $eventId, schoolId: $schoolId) {
      ${EVENT_USER}
    }
  }
`;

export const FETCH_COMPETITIONS_BY_EVENT = gql`
  query fetchCompetitionsByEvent($eventId: Int!){
    fetchCompetitionsByEvent(eventId: $eventId) {
      ${EVENT_COMPETITION}
    }
  }
`;

export const FETCH_COMPETITIONS_BY_EVENT_LIGHT = gql`
  query fetchCompetitionsByEvent($eventId: Int!){
    fetchCompetitionsByEvent(eventId: $eventId) {
      id
      title
      fetchCompetition {
        fetchCategory {
          id
          title
        }
      }
    }
  }
`;

export const FETCH_EVENT_JUDGES = gql`
  query eventJudges($eventId: ID!){
    eventJudges(eventId: $eventId){
      id
      roleId
      userId
      schoolId
      eventId
      status
      signIn
      fetchUser {
        id
        email
      }
      competitionsToJudge {
        id
        title
      }
    }
  }
`;

export const FETCH_EVENT_INVITED_JUDGES = gql`
  query eventInvitedJudges($eventId: ID!){
    eventInvitedJudges(eventId: $eventId){
      id
      email
      inviteUrl
      eventCompetitionId
    }
  }
`;

export const USER_FROM_REGION = gql`
  query userFromRegion($regionIds: [Int!]!){
    userFromRegion(regionIds: $regionIds){
      ${QUERY_USER}
    }
  }
`;

export const FETCH_UNJOIN_USERS_BY_EVENT = gql`
  query fetchUnregisteredUsersByEvent($eventId: Int!){
    fetchUnregisteredUsersByEvent(eventId: $eventId){
      ${QUERY_USER}
    }
  }
`;

export const SEARCH_REGION_USERS = gql`
  query searchRegionUsers($regionIds: [Int!]!, $query: String!){
    searchRegionUsers(regionIds: $regionIds, query: $query) {
      ${QUERY_USER}
    }
  }
`;

export const FETCH_ROLE_BY_PERMISSION = gql`
  query fetchRoleFromPermission($title: String!){
    fetchRoleFromPermission(title: $title){
      ${ROLE}
    }
  }
`;

export const FETCH_ROLES_BY_LEVEL = gql`
  query fetchRolesByLevel($userId: Int!){
    fetchRolesByLevel(
      userId: $userId
    ){
      ${ROLE}
    }
  }
`;

export const PENDING_TERM_AND_CONDITION_COUNT = gql`
  query fetchPendingTermAndCondition($eventId: Int!, $schoolId: Int!){
    fetchPendingTermAndCondition(eventId: $eventId, schoolId: $schoolId)
  }
`;

export const PENDING_TERM_AND_CONDITION_USERS = gql`
  query fetchPendingTermAndConditionUsers($eventId: Int!, $schoolId: Int!){
    fetchPendingTermAndConditionUsers(eventId: $eventId, schoolId: $schoolId) {
      ${EVENT_USER}
    }
  }
`;

export const PAID_USER_COUNT_AND_AMOUNT = gql`
  query paidUserCountAndAmount($eventId: Int!){
    paidUserCountAndAmount(eventId: $eventId)
  }
`;

export const PAYMENT_USER_AGAINST_REGISTRATION_OPTION = gql`
  query paymentUserAgainstRegistrationOption($eventId: Int!, $registrationOptionId: Int!){
    paymentUserAgainstRegistrationOption(eventId: $eventId, registrationOptionId: $registrationOptionId )
  }
`;

export const ALL_PAYMENTS_FOR_REGISTRATION_OPTION_AGAINST_EVENT = gql`
  query paymentAgainstRegistrationOption($eventId: Int!){
    paymentAgainstRegistrationOption(eventId: $eventId){
      id
      title
      tenantId
      paymentUser
    }
  }
`;

export const UNPAID_USER_AGAINST_REGISTRATION_OPTION = gql`
  query unpaidUserAgainstRegistrationOption($eventId: Int!, $registrationOptionId: Int!){
    unpaidUserAgainstRegistrationOption(eventId: $eventId, registrationOptionId: $registrationOptionId ){
      id
      firstname
      lastname
      email
      code
      amount(eventId: $eventId)
      defaultCurrency(eventId: $eventId)
    }
  }
`;

export const PAID_USER_AGAINST_REGISTRATION_OPTION = gql`
  query paidUserAgainstRegistrationOption($eventId: Int!, $registrationOptionId: Int!){
    paidUserAgainstRegistrationOption(eventId: $eventId, registrationOptionId: $registrationOptionId ){
      id
      firstname
      lastname
      email
      code
      amount(eventId: $eventId)
      defaultCurrency(eventId: $eventId)
    }
  }
`;

export const FETCH_SCHOOLS_BY_COACH = gql`
  query fetchSchoolsByCoach($eventId: Int!, $userId: Int!){
    fetchSchoolsByCoach(eventId: $eventId, userId: $userId){
      id
      fetchSchool{
        id
        name
      }
    }
  }
`;

export const FETCH_EVENT_COMPETITIONS_BY_GENDER = gql`
  query fetchEventCompetitionsByGender($eventId: Int!, $gender: String!){
    fetchEventCompetitionsByGender(eventId: $eventId, gender: $gender) {
      ${EVENT_COMPETITION}
    }
  }
`;

export const FETCH_EVENT = gql`
  query fetchEventDetail($id: Int!) {
    fetchEventDetail(id: $id) {
      id
      title
      pendingSchoolRequestCount
      activeSchoolRequestCount
      discordLink
      financialAidFormLink
      allowCoalition
      qualifiedAttendingPreviousEvent
    }
  }
`;

export const OVERALL_SCORES = gql`
query overallScore($eventId: Int!, $scoreableType: String!){
  overallScore(eventId: $eventId, scoreableType: $scoreableType)
}
`;

export const EVENT_SCORE_PROGRESS_STATS_SUMMARY = gql`
  query scoredCompetitions($eventId: Int!) {
    scoredCompetitions(eventId: $eventId)
  }
`;

export const EXPORT_SCORE_SUMMARY = gql`
  query exportScore($eventId: Int!, $format: String!) {
    exportScore(eventId: $eventId, format: $format)
  }
`;

export const COMPLETED_COMPETITION = gql`
  query completedCompetitions($eventId: Int!) {
    completedCompetitions(eventId: $eventId) {
      id
      title
      completed(eventId: $eventId){
        id
        title
      }
    }
  }
`;

export const INCOMPLETE_COMPETITION = gql`
  query inprogressCompetitions($eventId: Int!) {
    inprogressCompetitions(eventId: $eventId) {
      id
      title
      inprogress(eventId: $eventId){
        id
        title
      }
    }
  }
`;

export const INDIVIDUAL_SCORE = gql`
  query individualScore($eventId: Int!, $scoreableId: Int!, $scoreableType: String!){
    individualScore(eventId:$eventId, scoreableId: $scoreableId, scoreableType: $scoreableType){
      id
      eventId
      scoreableType
      scoreableId
      point
      user{
        id
        firstname
        lastname
        code
        profileImageUrl
      }
      school{
        id
        name
        regionIds
      }
      eventCompetition{
        id
        title
      }
    }
  }
`;
export const FETCH_EVENTS_USER_SCHOOLS = gql`
  query fetchEventsUsersByUser($eventId: Int!, $userId: Int!, $schoolId: Int){
    fetchEventsUsersByUser(eventId: $eventId, userId: $userId, schoolId: $schoolId) {
      id
      schoolId
      fetchSchool{
        id
        name
      }
    }
  }
`;

export const ROUNDS_BY_EVENT_COMPETITION = gql`
  query roundsByEventCompetition($eventCompetitionId: Int!){
    roundsByEventCompetition(eventCompetitionId: $eventCompetitionId){
      id
      name
      numberMatchups
      level
      status

      tenantId
    }
  }
`;
export const ROUND_SCORE = gql`
  query roundScore($roundId: ID!){
    roundScore(roundId: $roundId)
  }
`;

export const SPECIAL_NEED = gql`
  query specialNeed($eventId: Int!){
    specialNeed(eventId: $eventId)
  }
`;

export const COMPETITION_STATS = gql`
  query competitionStats($eventId: Int!){
    competitionStats(eventId: $eventId)
}
`;

export const CATEGORY_WITH_EVENT_COMPETITIONS = gql`
  query categoryWithEventCompetitions($eventId: Int!){
    categoryWithEventCompetitions(eventId: $eventId){
    id
    title
    eventCompetitions(eventId: $eventId){
      id
      title
      eventId
      competitionId
      status
      joinedMemberCount
      users
    }
  }
}
`;

export const USER_COUNT_AND_AMOUNT_AGAINST_COUNPON = gql`
  query userCountAndAmountAgainstCoupon($eventId: Int!, $couponId: Int!){
    userCountAndAmountAgainstCoupon(eventId:$eventId, couponId: $couponId){
    id
    code
    value
    valuePercentage
    eventId
    status
    couponUser

  }
}
`;

export const PARTICIPANTS_BY_COMPETITIONS = gql`
  query participantsByCompetition($eventCompetitionId: Int!){
    participantsByCompetition(eventCompetitionId:$eventCompetitionId)
  }
`;

export const APPLIED_USERS = gql`
  query appliedUsers($eventId: Int!, $couponId: Int!){
    appliedUsers(eventId: $eventId, couponId: $couponId){
    id
    firstname
    lastname
    couponAmount(eventId: $eventId)
    defaultCurrency(eventId: $eventId)
  }
}
`;

export const FETCH_EVENTS_USERS_COUNT_BY_COACH = gql`
  query fetchEventsUsersCountByCoach($eventId: Int!){
    fetchEventsUsersCountByCoach(eventId: $eventId)
  }
`;

export const FETCH_EVENTS_SCHOOL_TEAM = gql`
  query eventSchoolTeam($eventId: Int!, $schoolId: Int!) {
    eventSchoolTeam(eventId: $eventId, schoolId: $schoolId) {
      id
      code
    }
  }
`;

export const FETCH_SCHOOLS_USER = gql`
  query fetchSchoolsUser($userId: Int!, $schoolId: Int!) {
    fetchSchoolsUser(userId: $userId, schoolId: $schoolId) {
      id
      fetchSchool{
        id
        name
      }
      fetchUserAttachment{
        id
        verificationFileUrl
      }
    }
  }
`;

export const EVENT_COMPETITIONS_WITH_CATEGORY = gql`
  query categoryWithEventCompetitions($eventId: Int!){
    categoryWithEventCompetitions(eventId: $eventId){
    id
    title
    eventCompetitions(eventId: $eventId){
      id
      title
      eventId
      competitionId
      status
      joinedMemberCount
    }
  }
}
`;

export const FETCH_EVENT_SCHOOL_TEAMS = gql`
query eventSchoolTeams($eventId: ID!){
  eventSchoolTeams(eventId: $eventId){
    id
    status
    schoolId
    school{
      id
      name
    }
  }
}
`;

export const FETCH_TERMS_AND_CONDITIONS = gql`
query termsAndConditions($eventUserId: Int!){
  termsAndConditions(eventUserId: $eventUserId){
    eventTitle
    fullTermsAndConditions
    completed
  }
}
`;

export const GET_SCHOOL_SUBTEAMS = gql`
query fetchSchoolSubteams($eventId: ID!, $schoolId: ID!){
  fetchSchoolSubteams(eventId: $eventId, schoolId: $schoolId) {
    schoolSubteams {
      eventCompetitionId
      subteams {
        id,
        code
        size,
        maxSize
        full
      }
    }
  }
}
`;

export const GET_USER_SUBTEAM = gql`
query memberSubteam($eventCompetitionId: ID!){
  memberSubteam(eventCompetitionId: $eventCompetitionId) {
    id,
    code
    size,
    maxSize
    full
  }
}
`;

export const GET_USER_SUBTEAMS = gql`
query memberSubteams($eventCompetitionId: ID, $userId: ID!){
  memberSubteams(eventCompetitionId: $eventCompetitionId, userId: $userId) {
    id,
    code
    size,
    maxSize
    full
    eventCompetitionId
  }
}
`;

export const FETCH_ALL_BALLOTS = gql`
  query fetchAllBallots {
    fetchAllBallots {
      id
      title
      totalPoints
      fetchBallotSections {
        id
        title
        description
        totalPoints
      }
    }
  }
`;

export const FETCH_SCORE_FOR_JUDGABLE = gql`
  query fetchScoreForJudgable($eventCompetitionId: ID!, $roundId: ID!, $judgableId: ID!, $judgableType: String!) {
    fetchScoreForJudgable(eventCompetitionId: $eventCompetitionId, roundId: $roundId, judgableId: $judgableId, judgableType: $judgableType) {
      id
      totalScore
      sections {
        id
        points
        ballotSection { id }
      }
      comments
      privateNotes
    }
  }
`;

export const FETCH_BALLOT = gql`
  query fetchBallot($ballotId: Int!) {
    fetchBallot(ballotId: $ballotId) {
      id
      title
      totalPoints
      fetchBallotSections {
        id
        title
        description
        totalPoints
      }
    }
  }
`;

export const EVENT_COMPETITION_WITH_SCORE_DETAILS = gql`
  query eventCompetitionWithScores($id: Int!) {
    fetchEventCompetitionDetail(id: $id) {
      id
      title
      fetchCompetition {
        id
        fetchBallot {
          id
          fetchBallotSections {
            id
            title
            description
            totalPoints
          }
        }
      }
      rounds {
        id
        name
        level
        status
        judgables {
          id
          type
          code
          attachments {
            id
            attachmentName
            attachmentUrl
            createdAt
            updatedAt
          }
          members {
            id
            type
            code
            attachments {
              id
              attachmentName
              attachmentUrl
              createdAt
              updatedAt
            }
          }
          ballotScores {
            id
            totalScore
            judge { id fetchUser { firstname lastname email }}
            sections {
              ballotSection { id }
              points
            }
            comments
            privateNotes
          }
          penalty {
            id
            attendance
            penalty
          }
          totalScore
        }
      }
    }
  }
`;

export const EVENT_COMPETITION_WITH_SCORES = gql`
  query eventCompetitionWithScores($id: Int!) {
    fetchEventCompetitionDetail(id: $id) {
      id
      title
      rounds {
        id
        name
        level
        status
        judgables {
          id
          type
          code
          totalScore
          members {
            id
            code
            attachments {
              id
              attachmentName
              attachmentUrl
              createdAt
              updatedAt
            }
          }
          penalty {
            attendance
          }
          attachments {
            id
            attachmentName
            attachmentUrl
            createdAt
            updatedAt
          }
        }
      }
    }
  }
`;

export const FETCH_VALID_TIMEZONES = gql`
  query fetchValidTimezones {
    fetchValidTimezones 
  }
`;
