import React from 'react';

import useCurrentUser from '../../Hooks/userCurrentUser';
import useUserPermissionChecker from '../../Hooks/userUserPermissionChecker';
import Permissions from '../../Constants/PermissionConstrants';
import Loader from '../../Components/Loader';

import EventManageV2 from './EventManageV2';
import EventManage from './EventManage';

function shouldShowNewEventPage() {
  return Boolean(window.localStorage.getItem('useNewEventPage'));
}

export default function Event() {
  const {
    currentUser,
    loading: userLoading,
  } = useCurrentUser(true);
  const checkPermission = useUserPermissionChecker(currentUser);

  if (userLoading) {
    return <Loader />;
  }

  if (checkPermission(Permissions.CAN_VIEW_EVENT) && shouldShowNewEventPage()) {
    return <EventManageV2 />;
  }
  return <EventManage />;
}
